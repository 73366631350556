'use client';
import cn from 'classnames';
import Image from 'next/image';
import { VscCalendar } from 'react-icons/vsc';

import { Webinar } from '../lib/data';

import { getImageLink } from '@utils/getters';
import { useUserStore } from '@utils/user';

interface WebinarBoxProps {
  webinar: Webinar;
  watchLabel: string;
  className?: string;
}
export default function WebinarBox({ webinar, className, watchLabel }: WebinarBoxProps) {
  const { isPremium } = useUserStore();
  const parsedDate = new Date(Date.parse(webinar.date));
  const reformatedDate = parsedDate.toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className={cn('flex flex-col items-center justify-center lg:flex-row lg:items-stretch', className)}>
      <div className="relative h-32 w-32 flex-shrink-0 overflow-hidden rounded-2xl">
        <Image
          src={
            webinar.image?.sourceUrl
              ? getImageLink(webinar.image.sourceUrl)
              : 'https://cdn-trans.info/uploads/2024/01/d0a2354b4a7e76265b7e608c3e01.jpg'
          }
          alt={webinar.image?.altText || ''}
          width={280}
          height={280}
          className="absolute left-1/2 top-1/2 h-auto w-full -translate-x-1/2 -translate-y-1/2 object-cover"
        />
      </div>

      <div className="h-full flex-grow lg:h-32 lg:py-4">
        <div className="-mt-12 flex h-full w-full flex-col items-center justify-between rounded-xl border border-dark-700 p-4 pt-12 lg:-ml-1 lg:mt-0 lg:flex-row lg:rounded-l-none lg:pt-4 dark:bg-dark-500">
          <div className="flex flex-col items-center py-8 lg:items-start lg:py-0">
            <h3 className="flex flex-row items-center gap-2 text-dark-700 dark:text-magazine-500">
              <VscCalendar className="text-xl" />
              {reformatedDate}
            </h3>
            <p className="text-center text-sm font-semibold text-gray-700 lg:text-left dark:text-gray-200">
              {webinar.description}
            </p>
          </div>

          <a
            href={isPremium ? webinar.premiumUrl : webinar.url}
            target="_blank"
            className="whitespace-nowrap rounded-xl bg-gradient-to-r from-dark-700 to-dark-500 px-8 py-3 font-semibold text-white hover:from-magazine-800 hover:to-magazine-500 hover:text-white dark:from-magazine-500 dark:to-magazine-800 dark:hover:from-dark-500 dark:hover:to-dark-700"
            rel="noreferrer"
          >
            {watchLabel}
          </a>
        </div>
      </div>
    </div>
  );
}
