import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/public/static/magazine/envelope.svg");
import(/* webpackMode: "eager" */ "/app/public/static/magazine/faq.svg");
import(/* webpackMode: "eager" */ "/app/public/static/magazine/linkedin.svg");
import(/* webpackMode: "eager" */ "/app/public/static/magazine/mobile.svg");
import(/* webpackMode: "eager" */ "/app/public/static/svg/bell.svg");
import(/* webpackMode: "eager" */ "/app/public/static/svg/paperplane-line.svg");
import(/* webpackMode: "eager" */ "/app/public/static/svg/paperplane.svg");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/components/ContactForm/ContactFormForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/page/components/NewsletterSubscribeForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/page/webinary/components/Counter.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/page/webinary/components/WebinarBox.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/page/webinary/components/WebinarsCollapse.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Only/FreemiumOnly.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Only/LoggedInOnly.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Only/NotLoggedInOnly.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Only/PremiumOnly.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Tooltip/Tooltip.tsx")